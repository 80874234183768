a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
    transition: 0.3s;
}
a:hover {
    color: var(--secondary) !important;
    text-decoration: none;
}
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
    outline: 0;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button {
    border-radius: 0;
    outline: none !important;
    border: none;
    background: transparent;
}
button:hover {
    cursor: pointer;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

/* Buttons */
.btn {
    border: none;
    border-radius: 0.375rem;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    height: 100%;
    width: 100%;
    line-height: 1.25rem;
    padding: 0.75rem 1.5rem;
    text-align: center;
    text-transform: capitalize;
    -webkit-user-select: none;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
    text-decoration: none;
}
.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none;
}
.btn.disabled,
.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn_info {
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);
    min-width: fit-content !important;
    display: flex;
    align-items: center;
}
.btn_bordered {
    align-items: center;
    border: 1px solid #e4e6ee;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    margin-bottom: 1.75rem;
    position: relative;
    color: var(--text);
}
.btn_bordered:hover {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}
.btn_info:hover {
    color: #fff;
    background-color: var(--infohover);
    border-color: var(--infohover);
}
.btn_primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn_primary:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_danger {
    color: #fff;
    background-color: var(--danger);
    border-color: var(--danger);
}
.btn_danger:hover {
    color: #fff;
    background-color: var(--dangerhover);
    border-color: var(--dangerhover);
}
.btn_secondary {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_secondary:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
/* Inner Content */
.app_container {
    display: flex;
    min-height: 100vh;
    padding: 0%;
    margin: 0px !important;
    background-color: var(--lightblue);
}
.app_main {
    position: relative;
    flex-direction: column;
    z-index: 9;
    padding: 0 0px 0px 29vh !important;
    /* padding-top: 7vh; */
    width: 100%;

    transition: all 0.4s;
}
.app_mainsm {
    position: relative;
    flex-direction: column;
    z-index: 9;
    padding: 0 0px 0px 5.65rem !important;
    /* padding-top: 7vh; */
    width: 100%;

    transition: all 0.4s;
}
@media screen and (max-width: 900px) {
    .app_main {
        padding: 5px !important;
    }
    .app_mainsm {
        position: relative;
        flex-direction: column;
        z-index: 9;
        padding: 0px !important;
        /* padding-top: 7vh; */
        width: 100%;

        transition: all 0.4s;
    }
}
.app_main_translated {
    padding-right: 21.32vh;
    padding-left: 2vh;
}
.app_main .app_main_inner {
    flex: 1;
}
.app_inner {
    padding-top: 13vh !important;
}
.app_container_inner {
    background-color: #f8f8f8;
    padding-left: 12vh;
    padding-top: 3vh !important;
    min-height: 100vh;
}

/* Card */
.page_title {
    padding: 15px 25px;
    background: #fff;
    margin-bottom: 1.875rem;
    border-radius: 0.625rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.09);
    color: #828690 !important;
}
.card {
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0rem solid transparent;
    border-radius: 0.25rem;
    box-shadow: 0 0.1rem 1rem 0.1rem rgba(0, 0, 0, 0.05);
    height: auto;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    padding: 1.875rem;
}
.card_header {
    border-color: #f0f1f5;
    position: relative;
    background: transparent;
    /* padding: 1.5rem 1.875rem 0rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
    /* margin-bottom: 10px; */
    font-size: 1.6rem;
    font-family: Carbona-Black;
    color: #000;
    text-transform: capitalize;
}
.card_description {
    border-color: #f0f1f5;
    position: relative;
    background: transparent;
    /* padding: 0rem 1.875rem 1.25rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
    margin-bottom: 10px;
    font-size: 0.9rem;

    color: #000;
    text-transform: capitalize;
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}
@media (max-width: 800px) {
    body .card.mb-3 {
        margin-bottom: 15px !important;
    }
}
.sectiontitle {
    font-size: 15px;
    font-weight: 600;
}
.badge {
    min-width: 20px !important;
    height: 20px !important;
    background-color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 13px;
    color: #fff;
    font-weight: normal;
}
.postprofileimage {
    width: 50px;
    height: 50px;
}
.commentbox {
    background-color: #f0f2f5;
    /* border-radius: 10px; */
    padding: 10px;
}
.postprofileimage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 100px;
}
.timestamp {
    color: var(--light);
    font-size: 13px;
    opacity: 0.7 !important;
}
/* Search Bar */
.searchbar_container {
    padding: 0;
    margin: 0;
    border: 0;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 7px 10px 7px 10px;
    height: 40px;
}
.search_bar {
    width: 95%;
    height: 100%;
    padding: 0 10px;
    background: white;
    font-size: 14px;
    border-radius: 10px;
    transition: all 0.4s ease;
}

.icon_search {
    position: relative;
    top: 8px;
    width: 40%;
    height: 40%;
    opacity: 1;
    border-radius: 50%;
    border: 3px solid #022648;
    transition: opacity 0.25s ease, transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.icons_container {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    width: 35px;
    height: 35px;
    overflow: hidden;
}
.icon_close {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    cursor: pointer;
    transform: translateX(-200%);
    border-radius: 50%;
    transition: all 0.25s ease, transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.icon_close:hover i {
    color: #eac435;
}
.search_bar:focus + .icons_container .icon_close {
    opacity: 1;
    transform: translateX(0);
    color: var(--danger);
}
.searchbar_containerfilter .verical_divider {
    height: 25px;
    background-color: #ccc;
}
.image_preview {
    width: 10vh !important;
    height: 10vh !important;
    background-color: transparent;
    border-radius: 5px;
    border: 2px solid #ccc;
}
.image_preview img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 5px;
    object-fit: cover;
}

.avatar_edit input {
    display: none;
}
.avatar_upload .avatar_edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 1.32vh;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.avatar_upload .avatar_preview {
    width: 320px;
    height: 150px;
    background: #fff;
    border: 1px dashed #ebf0f6;
    border-radius: 4px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.avatar_upload_logo .avatar_preview {
    border-radius: 1.32vh;
}
.avatar_upload .avatar_preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.avatar_preview img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--primary);
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--secondary);
}
.imgpreviewtxt,
.imgpreviewpostertxt {
    position: absolute;
    top: 50%;
    margin-top: -1.32vh;
    transition: 0.3s;
    color: #cad1da;
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--secondary);
}

.roundbutton {
    padding: 7px 14px;
    min-width: 120px;
    cursor: pointer;
    box-shadow: 0 2px 6px -2px rgba(0, 106, 194, 0.2);
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: #fff;
    border-radius: 1.5rem;
    outline: none;
    transition: all 0.3s;
    font-size: 0.875rem;
}
.roundbutton:hover {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
}

.roundbuttonyellow {
    padding: 7px 14px;
    min-width: 120px;
    cursor: pointer;
    box-shadow: 0 2px 6px -2px rgba(0, 106, 194, 0.2);
    background-color: #f7a940;
    border: 1px solid #f7a940;
    color: #fff;
    border-radius: 1.5rem;
    outline: none;
    transition: all 0.3s;
    font-size: 0.875rem;
}
.roundbuttonyellow:hover {
    background-color: #f7a8409c;
    border: 1px solid #f7a8409c;
}

.tag {
    /* padding: 0.25rem 0.75rem;
    border-radius: 14px;
    background: #fff;
    border: solid 1px #e4e6ee;
    display: inline-flex;
    cursor: pointer;
    margin-right: 0.5rem;
    text-decoration: none;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    user-select: none;
    word-wrap: unset;
    font-size: 13px;
    font-weight: 400;
    transition: all 0.4s;
    align-items: center;
    color: var(--text); */
    margin-right: 0.5rem;
    background: #f5f5f5;
    border-radius: 0.375rem;
    display: inline-flex;
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding: 0.4375rem 0.75rem;
    cursor: pointer;
    transition: all 0.4s;
}
.tagnohover {
    padding: 0.25rem 0.75rem;
    border-radius: 14px;
    background: #fff;
    border: solid 1px #e4e6ee;
    display: inline-flex;
    cursor: pointer;
    margin-right: 0.5rem;
    text-decoration: none;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    user-select: none;
    word-wrap: unset;
    font-size: 13px;
    font-weight: 400;
    transition: all 0.4s;
    align-items: center;
    color: var(--text);
}
.tag:hover {
    background: #e9e9e9 !important;
    /* border: solid 1px #e9e9e9 !important; */
}

.shadowcard {
    /* display: flex; */
    /* flex: 150 0 auto; */
    padding: 20px;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0px 2px 6px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: all 0.4s;
    font-size: 14px;
    position: relative;
    min-height: 100px;
}
.shadowcard .label {
    color: #6b7280;
    font-weight: 400;
}
.shadowcard .title {
    font-weight: 600;
}

.shadowcard:hover {
    transform: translateY(-5%);
}

.shadowcardnohover {
    /* display: flex; */
    /* flex: 150 0 auto; */
    padding: 20px;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0px 2px 6px rgba(0, 0, 0, 0.06);
    /* cursor: pointer; */
    transition: all 0.4s;
    position: relative;
    min-height: 100px;
    font-size: 10.5px;
    font-weight: 700;
}
.shadshadowcardnohoverowcard .label {
    color: #6b7280;
    font-weight: 400;
}
.shadowcardnohover .title {
    font-weight: 600;
}
.ratingpill {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem;
    /* height: 1.3125rem; */
    justify-content: space-between;
    border-radius: 0.25rem;
    background-color: var(--success);
    font-size: 0.875rem;
    color: white;
}
.orrow {
    margin: 1rem auto;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1rem;
    color: #bcbcbc;
    font-family: Carbona-Black !important;
    line-height: 1.5rem;
    width: 6rem;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
    line-height: 0.1em;
}
.orrow span {
    background: #fff;
    padding: 0 0.5rem;
    font-size: 14px;
    text-transform: lowercase !important;
}

/* Checkbox */
.checkbox {
    --border: var(--text);
    --border-width: 2px !important;
    --border-hover: #959ed0;
    --tick: #fff;
    position: relative;
    background-color: transparent;
    /* color: var(--cardheader); */
}
.checkbox_label {
    font-size: 2.12vh;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
}
.checkbox:hover {
    /* color: var(--border-hover); */
}
.checkbox input,
.checkbox svg {
    width: 1.9vh;
    height: 1.9vh;
    display: block;
}
.checkbox_sub input {
    width: 2vh;
    height: 2vh;
}
.checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    border-width: 2px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}
.checkbox input:disabled {
    cursor: not-allowed;
}
.checkbox input:hover {
    /* --s: 2px; */
    /* --b: var(--border-hover); */
}
.checkbox input:checked {
    /* --b: var(--secondary); */
}
.checkbox svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    /* stroke: var(--stroke, var(--secondary)); */
    position: absolute;
    top: 0;
    left: 0;
    width: 2.12vh;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
}
.checkbox_sub svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--secondary));
    position: absolute;
    top: 0;
    left: 0;
    width: 2.12vh;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
}
.checkboxtranslated svg {
    right: 0;
}
.checkbox input:checked {
    --s: 2px;
    transition-delay: 0.4s;
}
.checkbox input:checked + svg {
    --a: 16.1 86.12;
    --o: 102.22;
}
.checkbox svg {
    stroke-dasharray: var(--a, 86.12);
    stroke-dashoffset: var(--o, 86.12);
    /* transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s; */
    transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

.container {
    display: flex;
    background: #fff;
    border: 1px solid #ebf0f6;
    border-radius: 0.375rem;
    padding: 1rem 0;
    /* width: 20.25rem; */
    position: relative;
    /* cursor: pointer; */
    transition: all 0.4s;
    flex-direction: column !important;
    font-size: 13px;
}

.wordbutton {
    font-size: 0.95rem !important;
    font-family: Carbona-Black !important;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--primary);
    cursor: pointer;
    transition: all 0.4s;
}

.wordbutton:hover {
    color: var(--secondary);
}

.tooltip {
    background: #999999;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 0.6rem;
    /* z-index: 1000; */
    cursor: pointer;
    position: relative;
}
.tooltipwrapper {
    background-color: #999999;
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: 1.64;
    text-align: center;
    border-radius: 6px;
    padding: 9px 10px;
    position: absolute;
    color: #ebf0f6;
    z-index: 200;
    min-width: 130px;
    /* max-width: max-content !important; */
    display: flex;
    visibility: hidden;
    transition: ease 0.4s !important;
    opacity: 0;
    top: 100%;
    justify-content: center;
    right: 0;
    /* right: 760px; */
}
.tooltip:hover .tooltipwrapper {
    /* display: flex !important; */
    opacity: 1;
    visibility: visible !important;
}
