input {
    outline: none;
    border: none;
}
textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}
input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
input:disabled {
    cursor: not-allowed !important;
}
button,
select {
    text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    -webkit-appearance: listbox;
}
textarea {
    resize: none;
}
/* Input Fields */
.input_group {
    position: relative;
    width: 100%;
}
.input_group i {
    position: absolute;
    top: 53%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 1rem;
    color: var(--light);
}
.input_group input {
    width: 100%;
    padding: 5px 5.3vh;
    font-size: 13px;
    background-color: white;
    border: 0.0625rem solid #c7c7c7;
    border-radius: 0.125rem;
    outline: none;
    transition: 0.3s;

    height: 45px;
}
.input_group input:focus {
    border: 1px solid #eac435 !important;
}
.input_group textarea {
    width: 100%;
    padding: 5px 7px;
    font-size: 13px;
    background-color: white;
    border: 1.5px solid #808080;
    border-radius: 1.32vh;
    outline: none;
    transition: 0.3s;

    min-height: 13.26vh !important;
    resize: none;
}
.input_group textarea:focus {
    border: 1px solid #eac435 !important;
}
.input_group textarea::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.scroll::-webkit-scrollbar {
    display: none;
}

.input_label {
    color: #808080;
    font-size: 2vh;
}
.btn_form {
    width: 160px;
    border-radius: 0px;
    height: 45px;
    background-color: var(--green);
    color: #b7d0bb;
    transition: 0.4s;
}
.btn_form:hover {
    background-color: var(--secondary);
}
.btn_formsignup {
    width: 35vh;
}
/*  */
.progressbarsteps > li.activestep ~ li:before,
.progressbarsteps > li.activestep:before {
    font-family: inherit;
}
.progressbarsteps > li.activestep ~ li:after,
.progressbarsteps > li.activestep:after {
    background-color: #ededed;
}
.progressbarsteps {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    list-style: none;
    padding: 0 150px;
}
.progressbarstepstext {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    list-style: none;
    /* padding: 0 12.65vh; */
}
.progressbarsteps > li {
    position: relative;
    color: white;
    z-index: 200 !important;
}
.progressbarsteps > li:nth-child(1) {
    flex: 1;
}
.progressbarsteps > li:before {
    content: '✓';
    display: block;
    /* margin: 0 4px 0 0; */
    background-color: var(--secondary);
    width: 3.31vh;
    height: 3.31vh;
    line-height: 23px;
    text-align: center;
    font-weight: bold;
    border-width: 2px;
    border-style: solid;
    border-color: var(--secondary);
    border-radius: 50%;
}
@media screen and (max-width: 600px) {
    .progressbarsteps > li:before {
        font-size: 1.32vh;
    }
}
.progressbarsteps > li:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: var(--secondary);
    position: absolute;
    top: 13px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
}
.progressbarsteps > li:last-child:after {
    width: 0;
}
.progressbarsteps > li.activestep:before {
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.progressbarsteps > li.activestep ~ li {
    color: transparent;
}
.progressbarsteps > li.activestep ~ li:before {
    background-color: #ccc;
    border-color: #ccc;
    z-index: 1000 !important;
    /* box-shadow: 0 0 30px #ccc; */
}
/*  */
.form__group {
    position: relative;
    padding: 2vh 0 0;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    align-items: start;
    flex-direction: column;
}
.form__field {
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #c7c7c7;
    border-radius: 0.125rem;
    padding: 0.3125rem 1.25rem;
    font-size: 13px;
    height: 2.875rem;
    transition: border-color 0.2s;
}
.form__fielddate {
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #f0f1f5;
    padding: 0.3125rem 1.25rem;
    height: 2.875rem;
    border-radius: 0.625rem;
    transition: border-color 0.2s;
}
.form__field::placeholder {
    color: #5e6b92 !important;
}
.form__field:placeholder-shown ~ .form__label {
    font-size: 14px !important;
    cursor: text;
    /* top: 25px; */
    color: #5e6b92 !important;
}
.form__label {
    /* position: absolute; */
    /* top: 40%; */
    left: 0;
    display: block;
    transition: 0.2s;
    font-size: 2.12vh;
    color: #1e223c !important;
}
.form__field:focus {
    border-color: var(--primary) !important;
}
.form__field:focus ~ .form__label {
    font-size: 13px;
    color: #5e6b92 !important;
    line-height: 1rem;
    left: 12px;
    top: 5px !important;
    background: #fff none repeat scroll 0 0;
    display: inline-block;
    padding: 2px 4px 0 4px;
    background: linear-gradient(to bottom, transparent 50%, #fff 50%);
}
.form__field:not(:placeholder-shown) {
    transition: 0.4s;
}
.form__fielddate:not(:placeholder-shown) ~ .form__label {
    font-size: 13px !important;
    color: #5e6b92 !important;
    line-height: 1rem;
    left: 12px;
    top: -10px !important;
    background: #fff none repeat scroll 0 0;
    display: inline-block;
    padding: 2px 4px 0 4px;
    background: linear-gradient(to bottom, transparent 50%, #fff 50%);
}
.form__field:not(:placeholder-shown) ~ .form__label {
    font-size: 13px !important;
    color: #5e6b92 !important;
    line-height: 1rem;
    left: 12px;
    top: 5px !important;
    background: #fff none repeat scroll 0 0;
    display: inline-block;
    padding: 2px 4px 0 4px;
    background: linear-gradient(to bottom, transparent 50%, #fff 50%);
}
/*  */
.formupperimage {
    max-width: 20%;
    position: absolute;
    right: -117px;
    top: -13.26vh;
    transform: rotate(-25deg);
}
.formmiddleimage {
    max-width: 20%;
    position: absolute;
    left: -11.32vh;
    top: 170px;
    transform: rotate(-25deg);
}
@media screen and (max-width: 800px) {
    .formupperimage {
        max-width: 40% !important;
        top: -13.26vh !important;
        right: -70px !important;
    }
    .formmiddleimage {
        max-width: 30% !important;
    }
    .form__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 1.5vh;
        color: #533e2d9d !important;
    }
}
@media screen and (max-width: 600px) {
    .formupperimage {
        max-width: 50% !important;
        top: -2.65vh !important;
        right: -75px !important;
    }
    .formmiddleimage {
        max-width: 30% !important;
    }
}
/* Checkbox */
.checkbox {
    --border: var(--primary);
    --border-width: 2px !important;
    --border-hover: #959ed0;
    --tick: #fff;
    position: relative;

    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    border-radius: 0.3125rem;

    cursor: pointer;
    /* display: inline-block; */
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 50px !important;
    font-size: 15px !important;

    padding: 0.75rem !important;

    /* color: var(--cardheader); */
}
.checkbox {
    position: relative;

    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0.3125rem;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 45px !important;
    font-size: 15px !important;

    padding: 0.74rem !important;
    background-color: #fff;
    border: 1px solid #e4e6ee;
}

.checkbox input,
.checkbox svg {
    width: 1.9vh;
    height: 1.9vh;
    display: block;
    margin-right: 10px !important;
}

.checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    border-width: 2px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--text));
}
.checkbox input:disabled {
    cursor: not-allowed;
}
.checkbox input:hover:not(:checked) {
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--primary)) !important;

    background-color: transparent;
}

.checkbox input:checked {
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--primary)) !important;
    background-color: var(--primary);
    --s: 2px;
    transition-delay: 0.4s;
}
.checkbox svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    /* stroke: var(--stroke, var(--secondary)); */
    position: absolute;
    top: 0;
    width: 2.12vh;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
}

.checkbox input:checked + svg {
    --a: 16.1 86.12;
    --o: 102.22;
    stroke: var(--stroke, white) !important;
}
.checkbox input:checked + label {
    background-color: red !important;
}
.checkbox svg {
    stroke-dasharray: var(--a, 86.12);
    stroke-dashoffset: var(--o, 86.12);
    transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    stroke: var(--stroke, white) !important;
}

/* Radio Buttons */
.radiobtn_label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
}
.radiobtn_label input {
    position: absolute;
    left: -9999px;
    display: none;
}
.radiobtn_label input:checked + span {
    background-color: #ffffff94;
    color: var(--focus);
    font-weight: 500;
}
.radiobtn_label input:checked + span:before {
    box-shadow: inset 0 0 0 0.3em var(--secondary);
}
.radiobtn_labelhovernone span:hover {
    background-color: transparent !important;
}
.radiobtn_labelhovernone:hover input:checked + span:before {
    box-shadow: inset 0 0 0 0.3em var(--secondary);
}
.radiobtn_labelhovernone input:checked + span {
    background-color: transparent !important;
}
.radiobtn_label span {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    font-weight: normal;
    color: var(--focus);
}
.radiobtn_label span:hover {
    background-color: #e6e6e6;
}
.radiobtn_labelhovernone span:hover {
    background-color: transparent !important;
}
.radiobtn_label span:before {
    display: flex;
    flex-shrink: 0;
    content: '';
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em var(--focus);
}
.radiobtn_label.text-grey span:before {
    box-shadow: inset 0 0 0 0.125em grey;
}
.radiobtn_label input.disabled {
    cursor: not-allowed !important;
}
.radiobtn_label_translated span:before {
    display: flex;
    flex-shrink: 0;
    content: '';
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-left: 0.375em;
    margin-right: 0;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em var(--focus);
}
.radiobtn_label_translated input.disabled {
    cursor: not-allowed;
}

.searchmenu {
    position: absolute;
    top: 40px;
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: opacity 0.4s !important;
    z-index: 100000000 !important;
    max-height: 0rem !important;
    min-height: 0rem !important;
    height: 0rem !important;
}
.searchmenushown {
    /* display: none !important; */
    visibility: visible !important;
    opacity: 1 !important;
    transition: opacity 0.4s !important;
    position: absolute;
    z-index: 98;
    background-color: #fff;
    left: 10;
    top: calc(100%);
    width: calc(97%);
    margin-left: -1px;
    max-height: 21.125rem;
    border: 1px solid #e4e6ee;
    border-top: none;
    box-sizing: border-box;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-radius: 0.3125rem;
    overflow-y: overlay;
    /* height: 0px !important; */
}

.searchmenushown::-webkit-scrollbar-track {
    background: transparent;
}

.searchmenushown::-webkit-scrollbar-thumb {
    border-radius: 0.3125rem;
    background-color: scrollbar-color;
}
.searchitem {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    padding: 0.5rem 1rem;
    min-height: 3.125rem;
    transition: all 0.4s;
}
.searchitem:hover {
    background-color: #f5f6fa;
}
